$widths: (20, 25, 33, 66, 75, 80);

dl {
  width: 100%;
  overflow: hidden;
  dt, dd {
    float: left;
    width: 50%;
    @each $width in $widths {
      &.w-#{$width} {
        width: #{$width * 1%} !important;
      }
    }
  }
}
